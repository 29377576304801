"use client";
import { emails } from "emails";
import { useRouter } from "next/navigation";
import { useState, useEffect } from "react";

const EmailForm = ({ email }) => {
  const router = useRouter();
  const [status, setStatus] = useState("noEmail");

  useEffect(() => {
    if (email) {
      setStatus(emails.includes(email) ? "hasAccess" : "accessRequested");
    }
  }, [email]);

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newEmail = formData.get("email");
    if (newEmail) {
      router.push(`/?email=${newEmail}`);
      setStatus(emails.includes(newEmail) ? "hasAccess" : "accessRequested");
      if (emails.includes(newEmail)) {
        router.refresh();
      } else {
        window.open(
          `https://forms.reform.app/ittybit/request-deck/dsvxrb?a7d14535-26f2-4dcf-a9b8-ad49d5f4c68d=${newEmail}`,
          "_blank"
        );
      }
    }
  }

  if (status === "noEmail") {
    return (
      <div className="relative w-full min-h-[50vh] -mt-[50vh] flex items-center justify-center p-24 bg-gradient-to-t from-dark-100 via-dark-100 from-80%">
        <div className="max-w-screen-xl mx-auto px-6 xl:px-0 space-y-6">
          <img
            src="https://ittybit-app.ittybitcdn.com/med_3b70dY17AsiehmWV/original"
            className="w-[120px] lg:w-[192px] h-auto mx-auto"
            width={192}
            height={240}
            alt="Pixel art of a padlock"
          />
          <h2 className="text-heading-2 text-1 text-center font-pixel">
            Request Access
          </h2>
          <p className="text-xl lg:text-2xl text-2 text-center">
            We're happy to share our pitch deck with good-fit investors. Please
            enter your email address below and we'll send you a private access
            link.
          </p>
          <form
            method="post"
            className="flex flex-col space-y-4 mx-auto max-w-sm"
            onSubmit={handleSubmit}
          >
            <input
              type="email"
              name="email"
              defaultValue={email}
              className="bg-dark-200 border-dark-500 focus:border-brand-500 text-1 placeholder:text-4 py-4 px-6 text-lg"
              placeholder="yourname@company.com"
              required
            />
            <button
              type="submit"
              className="px-6 py-4 text-lg text-1 bg-brand-500 hover:bg-brand-300 rounded-sm"
            >
              Request Access
            </button>
          </form>
        </div>
      </div>
    );
  }

  if (status === "accessRequested") {
    return (
      <div className="relative w-full min-h-[50vh] -mt-[50vh] flex items-center justify-center p-24 bg-gradient-to-t from-dark-100 via-dark-100 from-80%">
        <div className="max-w-screen-xl mx-auto px-6 xl:px-0 space-y-6">
          <img
            src="https://ittybit-app.ittybitcdn.com/med_3b70dY17AsiehmWV/original"
            className="w-[120px] lg:w-[192px] h-auto mx-auto"
            width={192}
            height={240}
            alt="Pixel art of a padlock"
          />
          <h2 className="text-heading-2 text-1 text-center font-pixel">
            Access Requested
          </h2>
          <p className="text-xl lg:text-2xl text-2 text-center">
            Thanks for your interest. We'll send you a private access link
            shortly.
          </p>
          <a
            href="/"
            className="flex flex-col items-center w-[240px] mx-auto px-3 py-2 text-base text-1 bg-dark-400 hover:bg-dark-500 rounded-sm"
          >
            Change Email Address
          </a>
        </div>
      </div>
    );
  }
};

export default EmailForm;
