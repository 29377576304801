"use client";
import { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "styles/prism.css";

const CodeBlock = ({
  children,
  language = "json",
  wrap,
  forcewrap,
  className = "",
}) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  let whitespace = "whitespace-pre";
  if (wrap) whitespace = "whitespace-pre-wrap";
  if (forcewrap) whitespace = "whitespace-pre-wrap break-all";

  let font = "font-code text-sm lg:text-base leading-6";
  if (className.includes("font-pixel"))
    font = "font-pixel text-base lg:text-lg leading-6";

  return (
    <div className={`block text-xs leading-5 overflow-x-visible ${className}`}>
      <div className="bg-black pt-1">
        <pre className={`language-${language} line-numbers ${whitespace} my-0`}>
          <code className={font}>{children}</code>
        </pre>
      </div>
    </div>
  );
};

export default CodeBlock;
