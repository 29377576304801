export const emails = [
  "yiran@venturestogether.com",
  "demoday@balticventures.uk",
  "yt@ittybit.com",
  "yc@ittybit.com",
  "jason@launch.co",
  "invest@joinodin.com",
  "dan@solidbond.com",
  "george.mensah@surevalleyventures.com",
  "oliver@conceptventures.vc",
  "hello@supernodeglobal.com",
  "k@ittybit.com",
  "pg@ittybit.com",
  "info@seedcamp.com",
  "fallon.richard@gmail.com",
  "katie.nicholson@msif.co.uk",
  "theteam@kelvincapital.com",
  "hello@kindredcapital.vc",
  "zashwin.k@mercia.co.uk",
  "george.mensah@surevalleyventures.com ",
  "hello@supernodeglobal.com",
  "peter@altereanmedia.com",
  "dan@solidbond.com",
  "oliver@conceptventures.com",
  "rockman.law@gmail.com",
  "info@fuel.ventures",
  "severin@nr.ventures",
  "sd@monspire.com",
  "neil@ehe.ai",
  "roakley@oxcp.com",
  "howard.simms72@googlemail.com",
  "joe@landscape.vc",
  "support@aigrant.org",
  "katie@twinpath.vc",
  "b@roksia.com",
  "chrisprucha@twitter.com",
  "ericcopeck@twitter.com",
  "adrind@twitter.com",
  "jeff_weinstein@twitter.com",
  "mxstbr@twitter.com",
  "siscodan@twitter.com",
  "tylermcginnis@twitter.com",
  "paulbassat@twitter.com",
  "andy_ayim@twitter.com",
  "chudson@twitter.com",
  "lennysan@twitter.com",
  "wizlikewizard@twitter.com",
  "dps@twitter.com",
  "thorwebdev@twitter.com",
  "jeffchang30@twitter.com",
  "siddharth87@twitter.com",
  "emerywells@twitter.com",
  "jamesbeshara@twitter.com",
  "ballmatthew@twitter.com",
  "efeng@twitter.com",
  "dsb@twitter.com",
  "ankurnagpal@twitter.com",
  "lachygroom@twitter.com",
  "eamonncarey@twitter.com",
  "hccmoller@twitter.com",
  "vedikaja_in@twitter.com",
  "rootvc@twitter.com",
  "terronk@twitter.com",
  "arnaudbonzom@twitter.com",
  "randallb@twitter.com",
  "drakerehfeld@twitter.com",
  "villageglobal@twitter.com",
  "jagsingh@twitter.com",
  "danmartell@twitter.com",
  "gdibner@twitter.com",
  "andreasklinger@twitter.com",
  "nick_franklin@twitter.com",
  "dcurtis@twitter.com",
  "swyx@twitter.com",
  "leahculver@twitter.com",
  "shl@twitter.com",
  "gabrielvaldivia@twitter.com",
  "amaldorai@twitter.com",
  "cecistalls@twitter.com",
  "rattrayalex@twitter.com",
  "stainlessapi@twitter.com",
  "timgl@twitter.com",
  "james406@twitter.com",
  "meganreyno@twitter.com",
  "zehanwang@twitter.com",
  "benlavender@twitter.com",
  "jkup@twitter.com",
  "zachtratar@twitter.com",
  "streamyard@twitter.com",
  "franzschrepf@twitter.com",
  "romeensheth@twitter.com",
  "bragiel@twitter.com",
  "bobbythakkar@twitter.com",
  "caseykcaruso@twitter.com",
  "iva01638448@twitter.com",
  "alanaagoyal@twitter.com",
  "dig_ventures@twitter.com",
  "nikita_arora17@twitter.com",
  "anjsud@twitter.com",
  "ali_moiz@twitter.com",
  "kayvz@twitter.com",
  "boldstartvc@twitter.com",
  "edsim@twitter.com",
  "ricburton@twitter.com",
  "rex_woodbury@twitter.com",
  "hugoamsellem@twitter.com",
  "lisawehden@twitter.com",
  "scotups@twitter.com",
  "zck@twitter.com",
  "shreyas@twitter.com",
  "turnernovak@twitter.com",
  "vhmth@twitter.com",
  "natfriedman@twitter.com",
  "jasoncwarner@twitter.com",
  "allendebevoise@twitter.com",
  "zachcoelius@twitter.com",
  "dflieb@twitter.com",
  "adlabossiere@twitter.com",
  "jaredpalmer@twitter.com",
  "rauchg@twitter.com",
  "kazanjy@twitter.com",
  "cjc@twitter.com",
  "spacecadet@twitter.com",
  "tweetsbycolin@twitter.com",
  "pejmannozad@twitter.com",
  "whoiskatrin@twitter.com",
  "iamharaldur@twitter.com",
  "svensson00@twitter.com",
  "cramforce@twitter.com",
  "developersvc@twitter.com",
  "auchenberg@twitter.com",
  "phhhoto@twitter.com",
  "doyouknowchamp@twitter.com",
  "alex_norcliffe@twitter.com",
  "martingtobias@twitter.com",
  "dtemkin@twitter.com",
  "esthercrawford@twitter.com",
  "chrissaad@twitter.com",
  "alexiskold@twitter.com",
  "video_sdk@twitter.com",
  "jonnyburch@twitter.com",
  "chrispavlovski@twitter.com",
  "janaboruta@twitter.com",
  "antwilson@twitter.com",
  "_shahedk@twitter.com",
  "nwischoff@twitter.com",
  "dougiesilkstone@twitter.com",
  "cyrusyari@twitter.com",
  "anthonyrose@twitter.com",
  "rsanghvi@twitter.com",
  "southpkcommons@twitter.com",
  "mrsimonbennett@twitter.com",
  "eniacvc@twitter.com",
  "hadley@twitter.com",
  "adriaanbloem@twitter.com",
  "liron@twitter.com",
  "she_travels@twitter.com",
  "leeerob@twitter.com",
  "lshvu@twitter.com",
  "fcollective@twitter.com",
  "epaley@twitter.com",
  "ehn@twitter.com",
  "bandrew@twitter.com",
  "uncorkcap@twitter.com",
  "destraynor@twitter.com",
  "indexventures@twitter.com",
  "martinmignot@twitter.com",
  "mhkt@twitter.com",
  "mvolpe@twitter.com",
  "bencasnocha@twitter.com",
  "gstrompolos@twitter.com",
  "jackconte@twitter.com",
  "steli@twitter.com",
  "kvogt@twitter.com",
  "stewart@twitter.com",
  "elatable@twitter.com",
  "stop@twitter.com",
  "noradio@twitter.com",
  "rrhoover@twitter.com",
  "collision@twitter.com",
  "bostonvc@twitter.com",
  "defunkt@twitter.com",
  "howardmarks@twitter.com",
  "patflynn@twitter.com",
  "christianhern@twitter.com",
  "ev@twitter.com",
  "bfeld@twitter.com",
  "eugenewei@twitter.com",
  "austen@twitter.com",
  "firstround@twitter.com",
  "chrisfralic@twitter.com",
  "chad_hurley@twitter.com",
  "susanwojcicki@twitter.com",
  "hnshah@twitter.com",
  "chrija@twitter.com",
  "pointninecap@twitter.com",
  "mikekarnj@twitter.com",
  "alexisohanian@twitter.com",
  "sevensevensix@twitter.com",
  "trueventures@twitter.com",
  "xavierc@twitter.com",
  "ykdojo@twitter.com",
  "rossysheil@twitter.com",
  "jhooks@twitter.com",
  "dominicmcgregor@twitter.com",
  "romainhuet@twitter.com",
  "patticus@twitter.com",
  "notdaniel@twitter.com",
  "neilswmurray@twitter.com",
  "bentossell@twitter.com",
  "chapterone@twitter.com",
  "jmj@twitter.com",
  "boostvc@twitter.com",
  "adamdraper@twitter.com",
  "kindredventures@twitter.com",
  "km@twitter.com",
  "abenzer@twitter.com",
  "pbricault@twitter.com",
  "gerstenzang@twitter.com",
  "richard_fearn@twitter.com",
  "jasonkilar@twitter.com",
  "joshmuccio@twitter.com",
  "wadefoster@twitter.com",
  "tomfrankly@twitter.com",
  "jack@altcapvc.com",
  "bala@altcapvc.com",
  "https://shlcap.notion.site",
  "https://www.seedchecks.com",
  "https://mikebifulco.com",
  "https://marcrandolph.com",
  "https://www.thepitch.show",
];
